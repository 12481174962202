import React, { useState, useEffect, useRef } from "react";
import env from "react-dotenv";
import "../styles/style.css";
import ApiService from "../api/apiService";
import ChatSideBarItem from "./chat-sidebar-item";
import MessageHead from "./message-head";
import MessageSeparator from "./message-separator";
import MessageSender from "./message-sender";
import MessageReceiver from "./message-receiver";
import showSwal from "./swal";
import ConversationModal from "./create-conversation-modal";
import PreviewImageModal from "./preview-image-modal";
import "font-awesome/css/font-awesome.min.css";

function Main(props) {
  const messageMainRef = useRef(null);

  const { userDetails } = props;
  let userId;
  if (userDetails && userDetails.id) {
    userId = userDetails.id;
  }
  const [conversationData, setConversationData] = useState(null);
  const [messagesData, setMessagesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasSelectedChat, setHasSelectedChat] = useState(false);
  const [storedConversationId, setConversationId] = useState(null);
  const [messageContent, setMessageContent] = useState("");
  const [conversationModalShow, setConversationModalShow] = useState(false);

  const [selectedFile, setSelectedFile] = useState("");
  const [filePreview, setFilePreview] = useState(null);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const [queryPageSize, setQueryPageSize] = useState(25);
  const queryPageSizeRef = useRef(queryPageSize);

  const [oldScrollHeight, setOldScrollHeight] = useState(0);
  const oldScrollHeightRef = useRef(oldScrollHeight);

  const [isAtBottom, setIsAtBottom] = useState(true);

  const checkScrollPosition = () => {
    const messageMain = messageMainRef.current;
    const isScrolledToBottom = messageMain.scrollHeight - messageMain.clientHeight <= messageMain.scrollTop + 1;
    setIsAtBottom(isScrolledToBottom);
  };

  const scrollToBottom = () => {
    const messageMain = messageMainRef.current;
    messageMain.scrollTo({
      top: messageMain.scrollHeight - messageMain.clientHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const messageMain = messageMainRef.current;
    messageMain.addEventListener("scroll", checkScrollPosition);
    return () => {
      messageMain.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  const closeModal = () => {
    setIsPreviewOpen(false);
    setTimeout(() => setPreviewImage(null), 300);
  };

  const handleImageClick = (imageSrc) => {
    setPreviewImage(imageSrc);
    setIsPreviewOpen(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFilePreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile("");
    setFilePreview(null);
  };

  const setConversationDetail = async (conversationId) => {
    try {
      setQueryPageSize(25);
      const conversationDetail = await ApiService.conversation.getConversationDetail(conversationId);
      const messagesDetail = await ApiService.message.getConversationMessages(conversationId);
      setConversationId(conversationId);
      setConversationData(conversationDetail);
      setMessagesData(messagesDetail);
      setHasSelectedChat(true);

      setTimeout(() => {
        scrollToBottom();
      }, 300);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!storedConversationId) return showSwal("Invalid Conversation!", "error", "", "OK");
    if (!messageContent) return showSwal("Message cannot be empty!", "info", "", "OK");

    try {
      const formData = new FormData();
      if (selectedFile !== "") formData.append("message_attachment", selectedFile);
      formData.append(
        "data",
        JSON.stringify({
          message: messageContent,
        })
      );
      const messageDetail = await ApiService.message.sendMessage(formData, storedConversationId);
      const { success } = messageDetail;
      if (success) {
        setMessageContent("");
        handleRemoveFile();
      }
    } catch (error) {
      setError(error);
    }
  };

  const showHideConversationModal = () => {
    setConversationModalShow(!conversationModalShow);
  };

  useEffect(() => {
    queryPageSizeRef.current = queryPageSize;
  }, [queryPageSize]);

  useEffect(() => {
    oldScrollHeightRef.current = oldScrollHeight;
  }, [oldScrollHeight]);

  useEffect(() => {
    let intervalId;

    const fetchMessages = async () => {
      if (!storedConversationId) return;
      try {
        const messageMainDiv = messageMainRef.current;
        const oldScrollHeight = oldScrollHeightRef.current;

        const messagesDetail = await ApiService.message.getConversationMessages(
          storedConversationId,
          1,
          queryPageSizeRef.current
        );

        setMessagesData(messagesDetail);

        // Check if user is at the very top before scrolling
        if (messageMainDiv.scrollTop === 0) {
          // Scroll to the old scroll height
          messageMainDiv.scrollTop = oldScrollHeight;
        }
      } catch (error) {
        setError(error);
      }
    };

    if (storedConversationId) {
      intervalId = setInterval(fetchMessages, 1000);
    }

    return () => clearInterval(intervalId);
  }, [storedConversationId]);

  useEffect(() => {
    const handleScroll = () => {
      const messageMainDiv = messageMainRef.current;
      const { scrollTop, scrollHeight, clientHeight } = messageMainDiv;

      if (scrollTop === 0) {
        setOldScrollHeight(scrollHeight);
        setQueryPageSize((queryPageSize) => queryPageSize + 25);
      }
    };

    const messageMainDiv = messageMainRef.current;
    messageMainDiv.addEventListener("scroll", handleScroll);

    return () => {
      messageMainDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const conversationProp = props.conversations ? props.conversations : [];
  const messagesDetail = messagesData ? messagesData : { messages: [] };

  let totalUnreadCount = 0;
  if (conversationProp.length > 0) {
    conversationProp.map((chatDetail) => {
      totalUnreadCount += parseInt(chatDetail.unread_count);
    });
  }
  return (
    <main className='main'>
      {conversationModalShow && <ConversationModal showHideConversationModal={showHideConversationModal} />}
      {previewImage && (
        <PreviewImageModal previewImage={previewImage} isPreviewOpen={isPreviewOpen} closeModal={closeModal} />
      )}
      <div className='chat-container'>
        <div className='chat'>
          <div className='chat__sidebar'>
            <div className='inbox'>
              <div className='inbox__head'>
                <span className='inbox__heading'>Inbox</span>
                <span className='inbox__unread-message-count js-unread-message-count'>{totalUnreadCount} New</span>
                <button className='inbox__add-btn' onClick={showHideConversationModal}>
                  <i className='fa fa-plus inbox__add-icon'></i>
                </button>
              </div>
              <div className='inbox__messages'>
                <ul className='inbox__list' id='inbox__list'>
                  {/* Chat Sidebar Item */}
                  {Array.from(conversationProp).map((chatDetail, index) => (
                    <ChatSideBarItem
                      chatDetail={chatDetail}
                      setConversationDetail={setConversationDetail}
                      key={index}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className='chat__main'>
            <div className='message'>
              <div className='message__head' id='message__head'>
                {/* MSG HEAD */}
                <MessageHead conversationData={conversationData} />
              </div>

              <div className='message__main' ref={messageMainRef}>
                <div className='message-container' id='message-container'>
                  {/* MSG CONTAINER */}
                  {renderChats(messagesDetail, userId, handleImageClick)}
                  {/* MSG CONTAINER */}
                </div>
                {!isAtBottom && (
                  <button className='scroll-to-bottom' onClick={scrollToBottom}>
                    <i className='fa fa-chevron-down'></i>
                  </button>
                )}
              </div>

              <div className='message__input-container'>
                <input
                  className='message__input js-message--input msg-tool'
                  placeholder='Write a message...'
                  value={messageContent}
                  onChange={(e) => setMessageContent(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey) {
                      e.preventDefault();
                      setMessageContent((prevContent) => prevContent + "\n");
                    } else if (e.key === "Enter") {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                />

                {filePreview && (
                  <div className='message__file-preview'>
                    {selectedFile.type.startsWith("image/") ? (
                      <img src={filePreview} alt='Preview' className='message__image-preview' />
                    ) : (
                      <div className='message__file-name'>{selectedFile.name}</div>
                    )}
                    <button className='message__btn-remove-file' onClick={handleRemoveFile}>
                      <i className='fa fa-times'></i>
                    </button>
                  </div>
                )}

                <input type='file' id='fileInput' className='message__file-input' onChange={handleFileChange} />

                <button
                  className='message__btn-file js-file-upload--btn msg-tool'
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <i className='fa fa-paperclip message__btn-file-icon'></i>
                </button>

                <button
                  className='message__btn-send js-send-message--btn msg-tool'
                  data-receiver=''
                  data-cid=''
                  onClick={() => handleSendMessage()}
                >
                  <i className='fa fa-paper-plane message__btn-send-icon'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

function renderChats(messagesData, currentUserId, handleImageClick) {
  const { messages } = messagesData;

  if (!messages || messages.length === 0) {
    return NoConversation();
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  let lastDate = "";

  const reversedMessages = [...messages].reverse();

  return reversedMessages.map((messageDetail, index) => {
    const { sender_id, createdAt } = messageDetail;
    const currentDate = formatDate(createdAt);
    const showSeparator = currentDate !== lastDate;

    lastDate = currentDate;

    return (
      <React.Fragment key={createdAt + index}>
        {showSeparator && <MessageSeparator date={currentDate} />}
        {sender_id === currentUserId ? (
          <MessageSender messageDetail={messageDetail} handleImageClick={handleImageClick} />
        ) : (
          <MessageReceiver messageDetail={messageDetail} handleImageClick={handleImageClick} />
        )}
      </React.Fragment>
    );
  });
}

function NoConversation() {
  return (
    <div className='no-messages-container'>
      <span className='test-element'>
        <center>No chats found.</center>
      </span>
    </div>
  );
}

export default Main;
