import Swal from "sweetalert2";

function showSwal(title, icon, text, confirmButtonText) {
  return Swal.fire({
    title,
    icon,
    text,
    confirmButtonText,
    confirmButtonColor: "#d33",
  });
}

export default showSwal;
