import "../styles/style.css";
import emptyImage from "../images/empty.jpg";

function MessageHead(props) {
  const { conversationData } = props;
  let conversationImage;
  if (conversationData) {
    conversationImage = conversationData?.conversation?.image?.includes("empty-filler-image.jpg")
      ? emptyImage
      : conversationData.conversation.image;
  } else {
    conversationImage = emptyImage;
  }

  return (
    <>
      <img src={conversationImage} alt='Seller image' className='message__head-img' />
      <div className='message__head-details'>
        <span className='message__head-name'>{conversationData?.conversation?.name}</span>
      </div>
      <button className='message__btn-more js-message_btn_more'>
        <svg className='message__btn-more-icon'>
          <use xlinkHref='img/sprite.svg#icon-dots-three-horizontal'></use>
        </svg>
        <ul className='message__dropdown u-hidden'>
          <li className='message__dropdown-item'>
            <a href='#' className='message__dropdown-link'>
              Delete
            </a>
          </li>
          <li className='message__dropdown-item'>
            <a href='#' className='message__dropdown-link'>
              Delete
            </a>
          </li>
          <li className='message__dropdown-item'>
            <a href='#' className='message__dropdown-link'>
              Delete
            </a>
          </li>
          <li className='message__dropdown-item'>
            <a href='#' className='message__dropdown-link'>
              Delete
            </a>
          </li>
        </ul>
      </button>
    </>
  );
}

export default MessageHead;
