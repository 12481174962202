import env from "react-dotenv";
import getToken from "../hooks/getToken";

const BASE_URL = `https://systems.buildhubware.com`;
const BASE_URL_DEV = `http://localhost:3000`;

const FINAL_API_URL = env.DEVELOPMENT === "true" ? BASE_URL_DEV : BASE_URL;

const ApiService = {
  account: {
    async login(username, password) {
      try {
        const response = await _fetch("/api/v1/accounts/login", "POST", {
          username,
          password,
        });
        if (!response.ok) throw new Error("Network response is not ok.");

        const result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
    async register(firstName, lastName, emailAddress, department, username, password) {
      try {
        const response = await _fetch("/api/v1/accounts/register", "POST", {
          first_name: firstName,
          last_name: lastName,
          email_address: emailAddress,
          department,
          username,
          password,
        });
        if (!response.ok) throw new Error("Network response is not ok.");

        const result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
  },
  token: {
    async verifyToken(token) {
      try {
        const response = await _fetch("/api/v1/tokens/verify", "POST", {
          token,
        });

        if (!response.ok) throw new Error("Network response is not ok.");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  conversation: {
    async getAllConversations() {
      try {
        const response = await _fetch("/api/v1/conversations", "GET");

        if (!response.ok) throw new Error("Network response is not ok.");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async getConversationDetail(conversationId) {
      try {
        const response = await _fetch(`/api/v1/conversations/${conversationId}`, "GET");

        if (!response.ok) throw new Error("Network response is not ok.");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async createConversation(request) {
      try {
        const response = await _fetch(`/api/v1/conversations`, "POST", request, true);

        if (!response.ok) throw new Error("Network response is not ok.");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async updateConversation(request, conversationId) {
      try {
        const response = await _fetch(`/api/v1/conversations/${conversationId}`, "PATCH", request);

        if (!response.ok) throw new Error("Network response is not ok.");
        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  message: {
    async getConversationMessages(conversationId, page = 1, limit = 25) {
      try {
        const response = await _fetch(`/api/v1/messages/${conversationId}?page=${page}&limit=${limit}`, "GET");

        if (!response.ok) throw new Error("Network response is not ok.");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async sendMessage(request, conversationId) {
      try {
        const response = await _fetch(`/api/v1/messages/${conversationId}`, "POST", request, true);

        if (!response.ok) throw new Error("Network response is not ok.");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  users: {
    async getAllusers() {
      try {
        const response = await _fetch(`/api/v1/users`, "GET");

        if (!response.ok) throw new Error("Network response is not ok.");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
};

function _fetch(apiUrl, reqMethod, reqBody, isFormData = false) {
  const token = getToken("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  let body;
  if (isFormData) {
    body = reqBody;
  } else {
    headers["Content-Type"] = "application/json";
    body = JSON.stringify(reqBody);
  }

  return fetch(`${FINAL_API_URL}${apiUrl}`, {
    method: reqMethod,
    headers: headers,
    body: body,
  });
}

export default ApiService;
