import React, { useState } from "react";
import env from "react-dotenv";
import "../styles/style.css";
import { convertTo12HourFormat } from "../util/helperFunctions";

function MessageSender(props) {
  const { messageDetail, handleImageClick } = props;
  const { content, createdAt, message_attachment, user } = messageDetail;

  const hasAttachment = message_attachment.length > 0;

  const BASE_URL = `https://systems.buildhubware.com/assets/message-attachment/`;
  const BASE_URL_DEV = `http://localhost:3000/assets/message-attachment/`;
  const FINAL_RESOURCES_URL = env.DEVELOPMENT === "true" ? BASE_URL_DEV : BASE_URL;

  const attachmentType = hasAttachment ? message_attachment[0].file_type : null;
  const attachmentLink = hasAttachment ? `${FINAL_RESOURCES_URL}${message_attachment[0].attachment}` : null;

  const isImageAttachment = attachmentType && attachmentType.includes("image");
  const isFileAttachment = attachmentType && attachmentType.includes("pdf");

  return (
    <>
      <div className='message__item-container message__item-container--sender'>
        <div className='message__item-message'>
          <span className='message__item-details'>
            <span className='message__item-time'>{convertTo12HourFormat(createdAt)}</span>
          </span>
          <span className='message__item-text message__item-text--sender'>
            {content}
            {hasAttachment &&
              (isImageAttachment ? (
                <img
                  src={attachmentLink}
                  alt='Attachment'
                  className='message__item-attachment'
                  onClick={() => handleImageClick(attachmentLink)}
                  style={{ cursor: "pointer" }}
                />
              ) : isFileAttachment ? (
                <div className='message__item-attachment'>
                  <a
                    href={attachmentLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='message__item-attachment-file'
                  >
                    Click to view the file
                  </a>
                </div>
              ) : (
                <div className='message__item-attachment'>Unsupported attachment type</div>
              ))}
          </span>
        </div>
      </div>
    </>
  );
}

export default MessageSender;
