import "../styles/style.css";
import "../styles/chat-sidebar-item.css";
import emptyImage from "../images/empty.jpg";
import { convertTo12HourFormat, trimWord } from "../util/helperFunctions";
import React, { useState, useRef } from "react";
import "font-awesome/css/font-awesome.min.css";
import ApiService from "../api/apiService";
import showSwal from "./swal";

function ChatSideBarItem(props) {
  const { chatDetail, setConversationDetail } = props;
  const { id, name, image, last_message, unread_count, updatedAt } = chatDetail;
  const { id: sender_id, content, sender } = last_message;
  const deleteRef = useRef(null);
  const leaveRef = useRef(null);

  const senderName = sender.display_name;
  const senderChat = content;
  let finalSenderName,
    finalSenderChat,
    finalChatContent = "";
  if (senderName && senderChat) {
    finalSenderName = senderName.split(" ")[0];
    finalSenderChat = trimWord(senderChat, 20);
    finalChatContent = `${finalSenderName}: ${finalSenderChat}`;
  }
  const timeStamp = convertTo12HourFormat(updatedAt);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleItemClick = () => {
    setConversationDetail(id);
  };

  const handleDelete = async () => {
    const delBtnId = deleteRef.current.getAttribute("data-value");
    const _deleteConversation = await ApiService.conversation.updateConversation({ action: "delete" }, delBtnId);
    const { success, msg } = _deleteConversation;
    if (!success) showSwal("Something went wrong.", "error", msg, "OK");
    setConversationDetail(null);
  };

  const handleLeave = async () => {
    const leaveBtnId = leaveRef.current.getAttribute("data-value");
    const _leaveConversation = await ApiService.conversation.updateConversation({ action: "leave" }, leaveBtnId);
    const { success, msg } = _leaveConversation;
    if (!success) showSwal("Something went wrong.", "error", msg, "OK");
    setConversationDetail(null);
  };

  const conversationImage = image.includes("empty-filler-image.jpg") ? emptyImage : image;

  return (
    <li
      className={`inbox__item ${unread_count > 0 ? "inbox__item--unread" : ""}`}
      data-value={id}
      data-name={name}
      data-sid={id}
      onClick={handleItemClick}
    >
      <input type='hidden' className='js-input-sellerId' value={id} />
      <img src={conversationImage} alt='Seller image' className='inbox__item-img' />
      <div className='inbox__item-details'>
        <span className='inbox__name'>{name}</span>
        <span className='inbox__message'>
          {/* Display last message here */}
          {finalChatContent}
        </span>
      </div>
      <div className='inbox__item-details inbox__item-details--2'>
        <span className='inbox__message-time'>{timeStamp}</span>
        <div className='dropdown' onClick={() => setShowDropdown(!showDropdown)}>
          <button className='dropdown-btn'>
            <i className='fa fa-ellipsis-v'></i>
          </button>
          <div className={`dropdown-content ${showDropdown ? "show" : ""}`}>
            <button data-value={id} onClick={handleDelete} ref={deleteRef}>
              Delete
            </button>
            <button data-value={id} onClick={handleLeave} ref={leaveRef}>
              Leave
            </button>
          </div>
        </div>
        <span className='inbox__unread-count'>{unread_count}</span>
      </div>
    </li>
  );
}

export default ChatSideBarItem;
