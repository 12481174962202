import React, { useState } from "react";
import "../styles/style.css";
import BuildHubLogo from "../images/buildhub.png";
import { userLogout } from "../hooks/accountHandler";

function Header(props) {
  const { userDetails } = props;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleUser = () => {
    // Logic for logging out the user
    alert("User Profile");
  };

  const handleLogout = () => {
    // Logic for logging out the user
    // alert("User Logged Out");
    userLogout();
  };

  return (
    <header className='header'>
      <a href='#'>
        <img src={BuildHubLogo} alt='Logo' className='logo' />
      </a>
      <h1 className='title heading-1'>| Chat</h1>

      <div className='profile-nav'>
        <div className='profile-container'>
          <button className='profile js-dropdown-btn' onClick={toggleDropdown}>
            <p className='profile__name'>{userDetails?.display_name}</p>
            <i className='fa fa-caret-down'></i>
          </button>
          <ul className={`selection ${dropdownVisible ? "" : "u-hidden"} js-dropdown`}>
            {/* <li className='selection__list'>
              <button className='btn-user' onClick={handleUser}>
                User Profile
              </button>
            </li> */}
            <li className='selection__list'>
              <button className='btn-logout' onClick={handleLogout}>
                Logout
              </button>
            </li>
          </ul>
        </div>
        <div className='header-icons'></div>
      </div>
    </header>
  );
}

export default Header;
