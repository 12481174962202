import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ChatIndex from "./layouts/index";
import SwalComponent from "./components/swal";
import "./styles/style.css";
import verifyToken from "./hooks/verifyToken";
import ApiService from "./api/apiService";
import { saveTokenToCookies, saveUserDetailsToLocalStorage } from "./hooks/accountHandler";

function App() {
  const [inputValues, setInputValues] = useState({
    username: "",
    password: "",
  });

  const [userDetails, setUserDetails] = useState({
    id: "",
    display_name: "",
  });

  const showLoginSwal = () => {
    withReactContent(Swal)
      .fire({
        title: <b>Login</b>,
        html: `
        <input type="text" id="username" class="swal2-input" placeholder="Username" value="${inputValues.username}">
        <input type="password" id="password" class="swal2-input" placeholder="Password" value="${inputValues.password}">
      `,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: false,
        confirmButtonText: "Login",
        confirmButtonColor: "#d33",
        preConfirm: () => {
          const username = Swal.getPopup().querySelector("#username")?.value || "";
          const password = Swal.getPopup().querySelector("#password")?.value || "";
          if (!username || !password) {
            Swal.showValidationMessage("You need to enter both username and password!");
            return false;
          }
          return { username, password };
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          const { username, password } = result.value;
          setInputValues({ username, password });
          callLoginApi(username, password);
        }
      });
  };

  const callLoginApi = async (username, password) => {
    Swal.fire({
      title: "Logging in...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const loginApi = await ApiService.account.login(username, password);
      Swal.close();
      const { success, token, userDetails } = loginApi;
      if (success) {
        saveTokenToCookies(token);
        setUserDetails(userDetails);
        saveUserDetailsToLocalStorage(userDetails);
        SwalComponent("Login Successful", "success", "", "OK");
      } else {
        SwalComponent("Login Failed", "error", "", "OK").then(() => {
          showLoginSwal();
        });
      }
    } catch (err) {
      Swal.close();

      SwalComponent("Login Failed", "error", "", "OK").then(() => {
        showLoginSwal();
      });
      console.error(err);
    }
  };

  useEffect(() => {
    const getUserDetails = () => {
      const userDetails = localStorage.getItem("userDetails");
      const _userDetails = JSON.parse(userDetails);

      setUserDetails(_userDetails);
    };
    const checkLoggedIn = async () => {
      const isLoggedIn = await verifyToken();
      if (!isLoggedIn) {
        showLoginSwal();
      } else {
        getUserDetails();
      }
    };

    checkLoggedIn();
  }, []);

  return (
    <div className='page'>
      <ChatIndex userDetails={userDetails} />
    </div>
  );
}

export default App;
