const saveTokenToCookies = (token) => {
  const date = new Date();
  date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = "token=" + token + ";" + expires + ";path=/";
};

const saveUserDetailsToLocalStorage = (userDetails) => {
  localStorage.setItem("userDetails", JSON.stringify(userDetails));
};

const userLogout = () => {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  localStorage.removeItem("userDetails");
  window.location.reload();
};

module.exports = {
  saveTokenToCookies,
  saveUserDetailsToLocalStorage,
  userLogout,
};
