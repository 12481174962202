export const convertTo12HourFormat = (timestamp) => {
  const date = new Date(timestamp);

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  const timeStr = `${hours}:${minutesStr} ${ampm}`;

  return timeStr;
};

export const dateToWords = (dateString) => {
  const date = new Date(dateString);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();

  return `${month} ${day}`;
};

export const trimWord = (word, maxLength) => {
  if (word.length > maxLength) {
    return word.slice(0, maxLength) + "...";
  } else {
    return word;
  }
};
