import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Main from "../components/main";
import ApiService from "../api/apiService";

function ChatIndex(props) {
  const { userDetails } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (userDetails.id && userDetails.display_name) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [userDetails]);

  useEffect(() => {
    if (isLoggedIn) {
      const fetchConversationData = async () => {
        try {
          const result = await ApiService.conversation.getAllConversations();
          setData(result);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };

      const intervalId = setInterval(fetchConversationData, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn]);

  return (
    <>
      <Header userDetails={userDetails} />
      <Main conversations={data?.conversations} userDetails={userDetails} />
    </>
  );
}

export default ChatIndex;
